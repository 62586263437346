import axios from "axios";
import loader from "@/loader.js";

export class currency {

    previousCurrencyCode = 'PLN'
    currencyCode = ''
    defaultCurrenty=null
    constructor(defaultCurrencyCode="GBP",ajaxUrl="",dropdown='',filedsWithPrice=[]) {

        this.defaultCurrencyCode = defaultCurrencyCode
        this.ajaxUrl = ajaxUrl
        this.currencyCode = defaultCurrencyCode
        this.dropdown = dropdown
        this.filedsWithPrice = filedsWithPrice

        this.getCurrency('PLN',defaultCurrencyCode).then((response)=>{
            this.defaultCurrenty = response.data
        });

        this.dropdownDedectChange()


    }

    dropdownDedectChange(){
        $(this.dropdown+' a').on('click',(e)=>{
            e.preventDefault()
            loader.start('#cena-kuriera')
            let previousCurrencyCode = ''
            let a = $(e.currentTarget)
            let currencyCode = a.text().trim()

            a.siblings().each((i,e)=>{
                if($(e).hasClass('active')){
                    previousCurrencyCode = $(e).text().trim()
                }
            })

            if(!a.hasClass('active')){
                a.siblings().removeClass('active')
                a.addClass('active')

                $('#currency-change button > span > span').text(currencyCode)
                $('#input_value').next().find('span').text(currencyCode)
                $('input[name="currency_code"]').val(currencyCode)

                this.calcTo(previousCurrencyCode,currencyCode).then((res)=>{
                    this.previousCurrencyCode = previousCurrencyCode
                    this.currencyCode = currencyCode
                    price.getPriceFromElements(this.filedsWithPrice)
                    price.recalculatePriceCurrency(res.data);

                    loader.finish('#cena-kuriera')
                })

            }

        })
    }

    async getCurrency(from,to){
        return await axios.post(this.ajaxUrl,{
            from: from,
            to: to
        })
    }
    async calcTo(from= this.previousCurrencyCode,to=this.currencyCode){
        return await this.getCurrency(from,to)
    }

}
