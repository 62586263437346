import { validate } from './validate.js'
import axios from "axios";
import {currency} from "@/currency.js";
import loader from "@/loader.js";
function responseObj(){

    this.response = {
        errors: []
    }
    this.add = (error,msg)=>{
        let obj = {
            error: error,
            msg: msg
        }
        this.response.errors.push(obj)
    }
}
export class packages {

    max_weight = 70 //kg
    max_length = 274 //cm
    max_circuit = 400 //cm
    min_weight_after_regular_weight = 30
    last_progi_from_db = '25_30'
    weight_after_basic_size = 0
    qty= 0

    default_values = {
        box:{
            weight : 1,
            length : 25,
            width : 20,
            height : 8,
            ddl_country_pickup : "",
            ddl_country_destination : "",
            qty: 1,
            value:0,
            contents:''
        },
        letter:{
            weight : 0.5,
            length : 35,
            width : 25,
            height : 3,
            ddl_country_pickup : "",
            ddl_country_destination : "",
            qty: 1,
            value:0,
            contents:''
        },
        pallet:{
            weight : 100,
            length : 120,
            width : 80,
            height : 60,
            ddl_country_pickup : "",
            ddl_country_destination : "",
            qty: 1,
            value:0,
            contents:''
        }
    }


    form = null

    constructor(){
        console.log('package class')

    }
    language(word){
        let langPL = {
            "Standard": "Przesyłka standardowa",
            "Expedited": "Przyspieszona wysyłka",
            "Saver": "Oszczędna przesyłka",
            "Express": "Przesyłka ekspresowa"
        }

        Object.keys(langPL).forEach((key)=>{
            if(key.toLocaleLowerCase().trim() === word.toLocaleLowerCase().trim()){
                word = langPL[key]
            }
        })

        return word

    }
    checkPackageRequirements(){
        let circuit = (2*this.width) + (2*this.height)

        let obj = new responseObj()

        //dlugosc
        if(this.length > this.max_length){
            obj.add(true,`Przekroczono maksymalny wymiar długości: ${this.max_length} cm`)
        }
        //obwod
        if(circuit > this.max_circuit){
            obj.add(true,`Przekroczono maksymalny obwód: ${this.max_circuit} cm`)
        }
        //waga
        if(this.weight > this.max_weight){
            obj.add(true,`Przekroczono maksymalną wagę: ${this.max_weight} kg`)
        }


        return obj.response
    }
    choosePackage(className){
        const htmlPackageType = $(className)
              htmlPackageType.on('click',(e)=>{

                $('#package-parameters-form,#countrys').show()

                $(e.currentTarget).siblings().removeClass('checked')
                $(e.currentTarget).addClass('checked')

                let packageType = $(e.currentTarget).data('type')

                $('input[name="package_type"]').val(packageType)

                let formInputs = $('#package-parameters-form').find('input')
                if(packageType==="letter"){

                    formInputs.each((i,e)=>{
                        console.log(e.id)
                        if(e.id !=='input_qty' && e.id !=='input_value'){
                            $(e).attr('readonly',true)
                        }

                    })
                    // $('#package-parameters-form').addClass('d-none')


                }else{
                    formInputs.each((i,e)=>{
                        $(e).removeAttr('readonly')

                    })
                    $('#package-parameters-form').removeClass('d-none')
                }

                this.resetAllFormFields(packageType)
                $('#paleta-info').remove()
                $('#check-prices-submit').attr('disabled',false)
            })
    }

    resetAllFormFields(type){


        $('#errors,#cena-kuriera').empty()

        $('#cena-kuriera').animate({
            opacity: 0,
            height: "-=250px"
        },1000)

        switch (type) {
            case "box":
                $('#input_weight').val(this.default_values.box.weight)
                $('#input_length').val(this.default_values.box.length)
                $('#input_width').val(this.default_values.box.width)
                $('#input_height').val(this.default_values.box.height)
                $('#input_qty').val(this.default_values.box.qty)
                $('#input_value').val(this.default_values.box.value)
                $('#input_contents').val(this.default_values.box.contents)
                $('#ddl_country_pickup').selectpicker('val',this.default_values.box.ddl_country_pickup)
                $('#ddl_country_destination').selectpicker('val',this.default_values.box.ddl_country_destination)

                break
            case "letter":
                $('#input_weight').val(this.default_values.letter.weight)
                $('#input_length').val(this.default_values.letter.length)
                $('#input_width').val(this.default_values.letter.width)
                $('#input_height').val(this.default_values.letter.height)
                $('#input_qty').val(this.default_values.letter.qty)
                $('#input_value').val(this.default_values.letter.value)
                $('#input_contents').val(this.default_values.letter.contents)
                $('#ddl_country_pickup').selectpicker('val',this.default_values.letter.ddl_country_pickup)
                $('#ddl_country_destination').selectpicker('val',this.default_values.letter.ddl_country_destination)
                break
            case "pallet":
                $('#input_weight').val(this.default_values.pallet.weight)
                $('#input_length').val(this.default_values.pallet.length)
                $('#input_width').val(this.default_values.pallet.width)
                $('#input_height').val(this.default_values.pallet.height)
                $('#input_qty').val(this.default_values.pallet.qty)
                $('#input_value').val(this.default_values.pallet.value)
                $('#input_contents').val(this.default_values.pallet.contents)
                $('#ddl_country_pickup').selectpicker('val',this.default_values.pallet.ddl_country_pickup)
                $('#ddl_country_destination').selectpicker('val',this.default_values.pallet.ddl_country_destination)
                break
        }



    }

    getPricesOfShipment(button){

            $(button).on('click',(e)=>{
                e.preventDefault()

                //const v = new validate('#order-shipment')
                const resultValidation = validation.forms()

                if(!resultValidation) return false

                const btnTypeOfPackage = $('.package-type')

                      btnTypeOfPackage.each((i,e)=>{

                          if($(e).hasClass('checked')){
                              this.btnTypeOfPackageChecked = $(e).data('type')
                          }
                      })


                    this.weight = parseInt($('#input_weight').val().trim())
                    this.length = parseInt($('#input_length').val().trim())
                    this.width = parseInt($('#input_width').val().trim())
                    this.height = parseInt($('#input_height').val().trim())
                    this.qty = parseInt($('#input_qty').val().trim())
                    this.ddl_country_pickup = parseInt($('#ddl_country_pickup').val())
                    this.ddl_country_destination = parseInt($('#ddl_country_destination').val())

                    let checkPackageRequirements = this.checkPackageRequirements()

                    //sprawdz max rozmiar dla paczki
                    if(checkPackageRequirements.errors.length === 0){
                        this.checkPriceOfShipment()
                    }else{
                        //info że trxeba zadzwonic

                        $('#package-parameters-form,#countrys').hide().parent().append(`<div id="paleta-info" class="py-4 w-100 d-flex align-items-center justify-content-center flex-column">
                                <div class="h4"><i class="bi bi-telephone"></i> <span class="pl-2">+48 62 366 63 66</span></div>
                                <div class="h5 font-weight-light text-center">Zadzwoń do nas, a my przedstawimy ci najlepszą ofertę</div>
                        </div>`)

                        $('#check-prices-submit').attr('disabled',true)
                        let alertHtml = `<div class="alert alert-warning alert-dismissible fade show" role="alert">                               `

                        if(checkPackageRequirements.errors.length){
                            checkPackageRequirements.errors.forEach((e)=>{
                                alertHtml+=`<p class="mb-0">${e.msg}</p>`
                            })
                        }
                        alertHtml +=`<button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                     </button>
                                    </div>`

                        $('#errors').empty().append(alertHtml)
                    }

            })


    }

    checkPriceOfShipment(){

        let obj = {
            weight: this.weight,
            length:this.length,
            width: this.width,
            height: this.height,
            qty: this.qty,
            ddl_country_pickup: this.ddl_country_pickup,
            ddl_country_destination: this.ddl_country_destination,
            btnTypeOfPackageChecked: this.btnTypeOfPackageChecked
        }

        let _this = this

        if($('#cena-kuriera').innerHeight()>240){
            $('#cena-kuriera').animate({
                opacity: 0,
                height: "-=250px"
            },1000,function(){
                _this.ajaxCallPriceOfShipment(obj)
            })

        }else{
            $('#cena-kuriera').css({'opacity':0})
            this.ajaxCallPriceOfShipment(obj)
        }




    }

    ajaxCallPriceOfShipment(obj){


        $('#cena-kuriera').empty()

        loader.start('#cena-kuriera')

        const data = axios.post('/api/get-price-of-shipment',obj)
              data.then((res)=>{


                let html = `<ul class="list-group list-group-flush">`

                for(let p=0;p<res.data.prices.length;p++){
                      switch (this.btnTypeOfPackageChecked) {
                          case 'box':
                              html += this.newBox(res.data.prices[p])
                              break;
                          case 'letter':
                              html += this.newLetter(res.data.prices[p])
                              break;
                          case 'pallet':
                              break;
                      }
                  }




                html += `</ul>`

                    $('#cena-kuriera').css({height:0}).append(html).append(()=>{
                      window.currency.calcTo().then((res)=>{

                          price.basePriceAdd = false
                          price.basePrices = []
                          price.getPriceFromElements(window.currency.filedsWithPrice)
                          price.recalculatePriceCurrency(res.data);



                      })
                  })

                    $('#cena-kuriera').animate({
                        opacity: 1,
                        height: "+=250px"
                    },1000,function(){

                        loader.finish('#cena-kuriera')

                    })


            }).catch((error)=>{
                console.log(error)
            })
        }

    newBox(services){
        let html ='';

            html += `<li class="list-group-item px-0  bg-transparent">
                        <div class="form-check pl-0  d-flex align-items-md-center justify-content-md-between">
                              <div>
                                  <input class="form-check-input d-none" type="checkbox" value="${services.service.code}" id="Check${services.service.code}">
                                  <label class="form-check-label" for="Check${services.service.code}">
                                    ${services.service.description}: <span data-price-element="${services.price.monetaryValue}" id="price_${services.service.code}" class="price-shipping">${services.price.monetaryValue}</span>
                                  </label>
                              </div>
                              <button data-servicelang="${services.service.description}" data-progi="" data-service="${services.service.code}" data-price="${services.price.monetaryValue}" type="button" name="submit_price_choosen" value="${services.service.code}" class="btn btn-outline-info btn-submit-ajax">Zamawiam</button>
                        </div>
                    </li>`


        return html
    }
    newLetter(services){
        let html ='';

            if(services.price.monetaryValue != null){
                html += `<li class="list-group-item px-0  bg-transparent">
                        <div class="form-check pl-0  d-flex align-items-md-center justify-content-md-between">
                              <div>
                                  <input class="form-check-input d-none" type="checkbox" value="${services.service.code}" id="Check${services.service.code}">
                                  <label class="form-check-label" for="Check${services.service.code}">
                                    ${services.service.description}: <span data-price-element="${services.price.monetaryValue}" id="price_${services.price.monetaryValue}" class="price-shipping">${services.price.monetaryValue}</span>
                                  </label>
                              </div>
                              <button data-servicelang="${services.service.description}" data-progi="" data-service="${services.service.code}" data-price="${services.price.monetaryValue}" type="button" name="submit_price_choosen" value="${services.service.code}" class="btn btn-outline-info btn-submit-ajax">Zamawiam</button>
                        </div>
                    </li>`
            }

        return html
    }
    box(services){
        //return this.newBox(services)
        let html ='';
        let increaseMin = 0.01
        let index=0
        Object.keys(services).forEach((e)=>{

            Object.keys(services[e]).forEach((p)=>{


                //progi wagowe z nazw kolumn
                let progi = p.split('_')

                let min = parseFloat(progi[0])
                let max = parseFloat(progi[1])

                if(!isNaN(min) && !isNaN(max)){
                    index++
                    if(index > 1){
                        min = min+increaseMin
                    }

                    if(this.weight >= min && this.weight <= max){

                        this.weight_after_basic_size = 0

                        html += `<li class="list-group-item px-0  bg-transparent">
                                    <div class="form-check pl-0  d-flex align-items-md-center justify-content-md-between">
                                          <div>
                                              <input class="form-check-input d-none" type="checkbox" value="${services[e].id}" id="Check${services[e].id}">
                                              <label class="form-check-label" for="Check${services[e].id}">
                                                ${this.language(e)}: <span data-price-element="${services[e][p]}" id="price_${services[e].id}" class="price-shipping">${parseFloat((services[e][p])).toFixed(2)}</span>
                                              </label>
                                          </div>
                                          <button data-servicelang="${this.language(e)}" data-progi="${p}" data-service="${services[e].shipment_service_id}" data-price="${services[e][p]}" type="button" name="submit_price_choosen" value="${services[e].id}" class="btn btn-outline-info btn-submit-ajax">Zamawiam</button>
                                    </div>
                                </li>`

                    }

                }
                else{
                    if((this.weight > this.min_weight_after_regular_weight && this.weight < this.max_weight)){
                        if(p === 'rates_every_kg'){
                            this.weight_after_basic_size = 1
                            let kgAfterSize =  this.weight - this.min_weight_after_regular_weight
                            let priceForkgAfterSize = kgAfterSize * parseFloat(services[e].rates_every_kg)
                            let priceOflastProgi = parseFloat(services[e][this.last_progi_from_db])
                            let price = priceForkgAfterSize+priceOflastProgi

                                html += `<li class="list-group-item px-0  bg-transparent">
                                                    <div class="form-check pl-0  d-flex align-items-md-center justify-content-md-between">
                                                          <div>
                                                              <input class="form-check-input d-none" type="checkbox" value="${services[e].id}" id="Check${services[e].id}">
                                                              <label class="form-check-label" for="Check${services[e].id}">
                                                                ${this.language(e)}: <span data-price-element="${services[e][p]}" id="price_${services[e].id}" class="price-shipping">${price.toFixed(2)}</span>
                                                              </label>
                                                          </div>
                                                          <button data-servicelang="${this.language(e)}" data-progi="${p}" data-service="${services[e].shipment_service_id}" data-price="${services[e][p]}" type="button" name="submit_price_choosen" value="${services[e].id}" class="btn btn-outline-info btn-submit-ajax">Zamawiam</button>
                                                    </div>
                                                </li>`
                        }
                    }
                }

            })
        })
        return html
    }
    letter(services){
        let html ='';

        Object.keys(services).forEach((e)=>{

            Object.keys(services[e]).forEach((p)=>{


               if(p === 'letter' && services[e][p] !== null){

                   html += `<li class="list-group-item px-0 bg-transparent">
                                <div class="form-check pl-0  d-flex align-items-md-center justify-content-md-between">
                                <div>
                                <input class="form-check-input d-none" type="checkbox" value="${services[e].id}" id="Check${services[e].id}">
                                      <label class="form-check-label" for="Check${services[e].id}">
                                        ${this.language(e)}: <span id="price_${services[e].id}" data-price-element="${services[e][p]}" class="price-shipping">${parseFloat(services[e][p]).toFixed(2)}</span>
                                      </label>
                                </div>
                                      <button data-servicelang="${this.language(e)}" data-progi="${p}" data-service="${services[e].shipment_service_id}" data-price="${services[e][p]}" type="button" name="submit_price_choosen" value="${services[e].id}" class="btn btn-outline-info btn-submit-ajax">Zamawiam</button>
                                </div>
                            </li>`

               }






            })
        })
        return html
    }
    pallet(){}
}


