export class price {

    basePrices= []
    basePriceAdd = false
    previousCurrencyCode = ''
    currencyCode = ''
    rate = 1.00
    input_wartosc = 0
    input_ilosc = 1
    vat = 1
    constructor() {
        this.input_ilosc = $('#input_qty')
    }

    getPriceFromElements(priceElements){
        let _this = this

        if(this.basePriceAdd) return false

        $(priceElements).each((i,e)=>{

            if(e.tagName === 'INPUT'){
                let objPriceInput = {}

                    if(e.id === 'input_value' && this.input_wartosc === 0) {
                        this.input_wartosc = parseFloat($(e).val().trim())
                        objPriceInput[e.id] = parseFloat($(e).val().trim())
                    }else if(e.id === 'input_value' && this.input_wartosc > 0){
                        objPriceInput[e.id] = this.input_wartosc
                    }
                    else{
                        objPriceInput[e.id] = parseFloat($(e).val().trim())
                    }


                _this.setBasePrice(objPriceInput)

            }else{
                let objPriceElement = {}
                    objPriceElement[e.id] = parseFloat($(e).text().trim())
                _this.setBasePrice(objPriceElement)

            }


        })

        _this.basePriceAdd = true
    }
    recalculatePriceCurrency(currency){
        this.rate = parseFloat(currency.rate)

        this.basePrices.forEach((elm)=>{
            Object.keys(elm).forEach((input_id)=>{

                let element_id = $('#'+input_id)
                let price = parseFloat(elm[input_id])

                let priceCalc = price * this.rate

                if(currency.code === "PLN"){
                    priceCalc = priceCalc * currency.vat
                }else if(currency.code === "GBP"){
                    priceCalc = price
                }

                if(element_id.prop('tagName') === 'INPUT'){
                    if(this.input_wartosc > 0){
                        if(currency.code === "GBP"){
                            priceCalc = price
                        }else{
                            priceCalc = this.input_wartosc * this.rate
                        }

                    }
                    element_id.val(this.formatPrice(priceCalc,2))
                }else{
                    element_id.parent().parent().parent().find('button.btn-submit-ajax').attr('data-price',this.formatPrice(priceCalc,2))
                    element_id.text(this.formatPrice(priceCalc,2,currency.code))
                }

            })

        })

    }

    setBasePrice(priceObj){
        if(!this.basePriceAdd)
            this.basePrices.push(priceObj)

    }

    calcPriceByCurrency(price,rate){
        return this.formatPrice(price*rate)
    }

    formatPrice(price,digits=2,currency_code =""){
        return price.toFixed(digits) + (currency_code !="" ? " "+currency_code : "")
    }

}
