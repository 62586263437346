import './bootstrap';
import 'bootstrap-select/sass/bootstrap-select.scss'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import '../sass/front.scss'

import 'bootstrap-select/dist/js/bootstrap-select.min.js'


import { packages } from './packages.js'
import { validate } from "./validate.js";
import { currency } from "./currency.js";
import { price } from "./price.js"
import { cart } from "./cart.js"

import loader from "./loader.js"
import Swal from 'sweetalert2';

window.cart = new cart()
window.Swal = Swal
window.loader = loader
window.price = new price('')
window.currency = new currency(
    'GBP',
    '/get-actual-rates',
    '#currency-change',
    '#input_value,.price-shipping'
)


import RevolutCheckout from '@revolut/checkout'
import {start} from "@popperjs/core";
       window.RevolutCheckout = RevolutCheckout

const v = new validate('#order-shipment',['input_weight','input_length','input_width','input_height','input_qty'])
      v.addFormInputNumberValidation()

const p = new packages()
      p.getPricesOfShipment('#check-prices-submit')
      p.choosePackage('.package-type')

window.validation = v
window.packages = p

//const c = new currency('GBP','/get-actual-rates')
        //c.calcTo('EUR',c.defaultCurrency)


$('.zamow-kurier-icons > div').hover(function(){

    let type = $(this).data('type')

    if(type=="box"){

        $('.colorbg-depends').css({backgroundColor:"rgb(52 58 64 / 100%)"})
    }else if(type=="letter"){

        $('.colorbg-depends').css({backgroundColor:"rgb(52 58 64 / 95%)"})
    }else if(type=="pallet"){

        $('.colorbg-depends').css({backgroundColor:"rgb(52 58 64 / 90%)"})
    }

}, function(){
    let checked = $('.zamow-kurier-icons').find('.checked')
    let type = checked.data('type')
    if(type=="box"){

        $('.colorbg-depends').css({backgroundColor:"rgb(52 58 64 / 100%)"})
    }else if(type=="letter"){

        $('.colorbg-depends').css({backgroundColor:"rgb(52 58 64 / 95%)"})
    }else if(type=="pallet"){

        $('.colorbg-depends').css({backgroundColor:"rgb(52 58 64 / 90%)"})
    }
});
function bodyResolution(){
    const minHeight = ($(window).innerHeight() - $('header').innerHeight()) - $('footer').innerHeight()
    $('main').css({minHeight: minHeight+'px'})
}
bodyResolution()
$(window).on('resize',function(){
    bodyResolution()
})

