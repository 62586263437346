const loader = {
    start: (element)=>{
        $(element).prepend(`<div class="loader"><div class="spinner-border text-success" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div></div>`)
    },
    finish: (element)=>{
        $(element+' .loader').fadeOut(function(){
            $(this).remove()
        })
    }
}

export default loader
