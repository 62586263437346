export class validate {

    form = null
    isValid = true
    input_grater_then_zero = []
    constructor(form,input_grater_then_zero) {
        this.form = $(form)
        this.input_grater_then_zero = input_grater_then_zero
        let _this = this
        let formdata = this.form.find('input[type="text"],select')
        var errors = []
        formdata.each(function(i,e){
            if(e.tagName === "SELECT"){
                $(e).on('change',function(){
                    if($(this).val() === ''){
                        $(e).parent().next('.invalid-feedback').show()
                        errors.push(0)
                    }else{
                        $(e).parent().next('.invalid-feedback').hide()

                    }
                })

            }else if(e.tagName === "INPUT"){
                $(e).on('keyup',function(){

                    if($(this).val() === ''){
                        $(e).parent().next('.invalid-feedback').text('Wartość nie może być pusta').show()

                    }else if(_this.input_grater_then_zero.includes($(this).attr('name')) && $(this).val() == 0){
                        $(e).parent().next('.invalid-feedback').text('Wartość nie może być 0').show()
                    }
                    else{
                        $(e).parent().next('.invalid-feedback').hide()

                    }
                })

            }
        })
    }
    select =(select)=>{

        if(select.value === ''){
            console.log(select)
            return false
        }
    }
    input=(input)=>{
        if(input.value === ''){
            console.log(input)
            return false
        }
    }
    forms() {

        this.form.addClass('was-validated')
        let _this = this
        let formdata = this.form.find('input[type="text"],select')
        var errors = []
            formdata.each(function(i,e){
                if(e.tagName === "SELECT"){
                    if(e.value === ""){
                        $(e).parent().next('.invalid-feedback').show()
                        errors.push(0)
                    }else{
                        $(e).parent().next('.invalid-feedback').hide()
                    }
                }else if(e.tagName === "INPUT"){
                    if(e.id === "input_contents"){
                        console.log($('#zawartosc-wybrane li').length === 0)
                        if($('#zawartosc-wybrane li').length === 0){
                            $(e).parent().next('.invalid-feedback').show()
                            $(e).parent().next('.invalid-feedback').text('Nie wybrano z listy')
                            errors.push(0)
                        }
                    }else if(e.value === ""){
                        $(e).parent().next('.invalid-feedback').show()
                        errors.push(0)
                    }else if(_this.input_grater_then_zero.includes($(this).attr('name')) && $(this).val() == 0){
                        $(e).parent().next('.invalid-feedback').text('Wartość nie może być 0').show()
                    }else{
                        $(e).parent().next('.invalid-feedback').hide()
                    }
                }
            })

        if(errors.length > 0) return false
        else return true


    }

    addFormInputNumberValidation = () =>{
        let formdata = this.form.find('input,select')

        let _this = this
        formdata.each(function(i,e){

            if(e.tagName === "SELECT"){

            }else if(e.tagName === "INPUT"){

                if(e.classList.contains("numeric"))
                    _this.setInputFilter(e, function(value) {
                        return /^\d*\.?\d*$/.test(value); // Allow digits and '.' only, using a RegExp.
                    }, "Tylko liczby, liczby przedzielone .");


            }
        })
    }

     setInputFilter = (textbox, inputFilter, errMsg) => {
        [ "input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop", "focusout" ].forEach(function(event) {
            textbox.addEventListener(event, function(e) {

                if (inputFilter(this.value)) {
                    // Accepted value.
                    if ([ "keydown", "mousedown", "focusout" ].indexOf(e.type) >= 0){
                        this.classList.remove("input-error");
                        this.setCustomValidity("");
                    }

                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                }
                else if (this.hasOwnProperty("oldValue")) {
                    // Rejected value: restore the previous one.
                    this.classList.add("input-error");
                    this.setCustomValidity(errMsg);
                    this.reportValidity();
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                }
                else {
                    // Rejected value: nothing to restore.
                    this.value = "";
                }
            });
        });
    }

    checkIsValid = ()=>{
        return this.isValid
    }

}
